import { Text } from '@chakra-ui/react';
import { TCountryOption } from '@codeer/handypark-common';
import { AppFlagIcon } from '@codeer/handypark-web-common';
import flags from 'country-flag-icons/react/3x2';
import { components, OptionProps } from 'react-select';

export const CountrySelectInputElementOption = (props: OptionProps<TCountryOption>) => {
  const { children, data } = props;

  return (
    <components.Option {...props}>
      <AppFlagIcon country={data.value as keyof typeof flags} alignSelf={'center'} me={2} />
      <Text as={'span'} display={'inline-block'}>
        {children}
      </Text>
    </components.Option>
  );
};
