import { PhoneNumberInput, TPhoneNumberInputProps } from '@/core';
import { TAppControlledComponentsProps } from '@codeer/handypark-web-common';

import { Controller, FieldValues } from 'react-hook-form';

type TAppControlledPhoneNumberProps<T extends FieldValues> = TAppControlledComponentsProps<T> &
  TPhoneNumberInputProps;

export const AppControlledPhoneNumber = <T extends FieldValues>({
  control,
  name,
  label,

  phoneNumber,
  onPhoneNumberChange,
  phoneNumberOrigin,
  onPhoneNumberOriginChange,
  onSubmit,

  ...rest
}: TAppControlledPhoneNumberProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <PhoneNumberInput
          id={name}
          {...field}
          error={fieldState.error}
          label={label}
          phoneNumber={phoneNumber}
          onPhoneNumberChange={onPhoneNumberChange}
          phoneNumberOrigin={phoneNumberOrigin.toUpperCase()}
          onPhoneNumberOriginChange={onPhoneNumberOriginChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSubmit && onSubmit(e);
            }
          }}
          {...rest}
        />
      )}
    />
  );
};
