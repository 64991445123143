import { CountrySelectInputElement } from '@/core';
import { FormControl, Input, InputGroup } from '@chakra-ui/react';
import {
  AppFormErrorMessage,
  AppFormHelperText,
  AppFormLabel,
  AppFormLabelAsContainer,
  TAppInputProps,
} from '@codeer/handypark-web-common';
import { FC, forwardRef, ReactElement } from 'react';
import './phone-number-input.style.scss';

export type TPhoneNumberInputProps = Omit<
  TAppInputProps,
  'leftIcon' | 'rightIcon' | 'placeholder' | 'autoComplete' | 'value' | 'onChange'
> & {
  phoneNumberOrigin: string;
  onPhoneNumberOriginChange?: (country: string) => void;

  phoneNumber: string;
  onPhoneNumberChange?: (phoneNumber: string) => void;
};

export const PhoneNumberInput: FC<TPhoneNumberInputProps> = forwardRef(
  (
    {
      qa = 'app-input',
      label,
      description,
      size = 'regular',
      isRequired,
      id,
      error,
      showErrorMessage = true,

      phoneNumberOrigin,
      onPhoneNumberOriginChange,

      phoneNumber,
      onPhoneNumberChange,

      ...rest
    },
    ref,
  ): ReactElement => {
    return (
      <FormControl isInvalid={Boolean(error)} isRequired={isRequired}>
        <AppFormLabel qa={`${qa}-label`} htmlFor={id} error={error}>
          {label}
        </AppFormLabel>
        <AppFormHelperText qa={`${qa}-helper-text`}>{description}</AppFormHelperText>

        <AppFormLabelAsContainer qa={`${qa}-label`} label={label}>
          <InputGroup
            size={size}
            mt={0}
            position={'relative'}
            zIndex={1}
            data-input-group-error={!!error ? true : undefined}
          >
            <CountrySelectInputElement
              value={phoneNumberOrigin}
              onChange={option =>
                onPhoneNumberOriginChange && onPhoneNumberOriginChange(option.value)
              }
              inputElementProps={{ width: '4.25rem' }}
            />
            <Input
              ref={ref}
              pl='4.75rem' // .5 more rem as the width of our input element
              {...rest}
              autoComplete={'off'}
              value={phoneNumber}
              onChange={e => onPhoneNumberChange && onPhoneNumberChange(e.target.value)}
            />
          </InputGroup>
        </AppFormLabelAsContainer>

        {showErrorMessage && (
          <AppFormErrorMessage qa={`${qa}-error-message`}>{error?.message}</AppFormErrorMessage>
        )}
      </FormControl>
    );
  },
);
