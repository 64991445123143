import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButton,
  AppContainer,
  AppControlledCheckbox,
  AppFormErrors,
  AppLink,
  AppTrans,
  getErrorMessages,
  windowScrollTop,
} from '@codeer/handypark-web-common';
import { AppControlledPhoneNumber } from '@core';
import {
  acceptedPrivacyPolicyAtom,
  acceptedProfessionalMessagesAtom,
  PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS,
  parkingCardCountryCodeAtom,
  parkingCardCountryCodeForeignStepAtom,
  phoneNumberAtom,
  phoneNumberOriginAtom,
} from '@features/parking-card-country-code';

import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom } from 'jotai';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { ReactNode, useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS,
  parkingCardCountryCodeForeignFillPhoneNumberValidationSchema,
  TParkingCardCountryCodeForeignFillPhoneNumberForm,
} from './';

const keyPrefix = 'parking-card-country-code-foreign.fill-phone-number.form';
export const ParkingCardCountryCodeForeignFillPhoneNumberForm = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix,
  });

  const [formErrors, setFormErrors] = useState<ReactNode[]>([]);

  const [phoneNumber, setPhoneNumber] = useAtom(phoneNumberAtom);
  const [phoneNumberOrigin, setPhoneNumberOrigin] = useAtom(phoneNumberOriginAtom);
  const parkingCardCountryCode = useAtomValue(parkingCardCountryCodeAtom);

  useEffect(() => {
    if (phoneNumberOrigin === '') {
      setPhoneNumberOrigin(parkingCardCountryCode);
    }
  }, []);

  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useAtom(acceptedPrivacyPolicyAtom);
  const [acceptedProfessionalMessages, setAcceptedProfessionalMessages] = useAtom(
    acceptedProfessionalMessagesAtom,
  );

  const setParkingCardCountryCodeForeignStep = useSetAtom(parkingCardCountryCodeForeignStepAtom);

  const defaultValues = {
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.PHONE_NUMBER_ORIGIN]:
      phoneNumberOrigin,
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.PHONE_NUMBER]: phoneNumber,
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PRIVACY_POLICY]:
      acceptedPrivacyPolicy,
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PROFESSIONAL_MESSAGES]:
      acceptedProfessionalMessages,
  };

  const form = useForm<TParkingCardCountryCodeForeignFillPhoneNumberForm>({
    resolver: yupResolver(parkingCardCountryCodeForeignFillPhoneNumberValidationSchema()),
    mode: 'onSubmit',
    values: defaultValues,
    defaultValues,
  });
  const { handleSubmit, control } = form;

  const resetFormErrors = () => {
    setFormErrors([]);
  };

  const onSubmit: SubmitHandler<TParkingCardCountryCodeForeignFillPhoneNumberForm> = data => {
    /* Parse our filled in phone number and store the number with national number included */
    const parsedPhoneNumber = parsePhoneNumber(
      data[PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.PHONE_NUMBER],
      data[
        PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.PHONE_NUMBER_ORIGIN
      ] as CountryCode,
    );
    setPhoneNumber(parsedPhoneNumber.number);

    setAcceptedPrivacyPolicy(
      data[PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PRIVACY_POLICY],
    );
    setAcceptedProfessionalMessages(
      data[PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PRIVACY_POLICY],
    );

    resetFormErrors();
    setParkingCardCountryCodeForeignStep(
      PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.CONFIRM_PHONE_NUMBER,
    );
  };

  const onError: SubmitErrorHandler<TParkingCardCountryCodeForeignFillPhoneNumberForm> = data => {
    const newFormErrors = getErrorMessages(data);
    setFormErrors(newFormErrors);
    windowScrollTop();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <AppContainer mb={8}>
        <AppFormErrors formErrors={formErrors} mb={8} />

        <AppControlledPhoneNumber
          name={PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.PHONE_NUMBER}
          control={control}
          phoneNumber={phoneNumber}
          isRequired={true}
          onPhoneNumberChange={setPhoneNumber}
          phoneNumberOrigin={phoneNumberOrigin.toUpperCase()}
          onPhoneNumberOriginChange={setPhoneNumberOrigin}
          onSubmit={handleSubmit(onSubmit, onError)}
          label={t('phone-number.label')}
          showErrorMessage={false}
          w={{ base: 'full', sm: '16rem' }}
          mb={4}
        />

        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <AppControlledCheckbox
            control={control}
            name={
              PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PRIVACY_POLICY
            }
            isRequired={true}
            showErrorMessage={false}
          >
            <AppTrans
              i18nKey={`${keyPrefix}.accept-privacy-policy.label`}
              ns={T_NAMESPACES.PAGES}
              components={{
                a: (
                  <AppLink
                    href={t('accept-privacy-policy.link.href')}
                    aria-label={t('accept-privacy-policy.link.aria-label')}
                  />
                ),
              }}
            />
          </AppControlledCheckbox>

          <AppControlledCheckbox
            control={control}
            name={
              PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PROFESSIONAL_MESSAGES
            }
            textStyle={'paragraph'}
            showErrorMessage={false}
          >
            {t('accept-professional-messages.label')}
          </AppControlledCheckbox>
        </Box>
      </AppContainer>
      <AppContainer mb={8}>
        <AppButton onClick={handleSubmit(onSubmit, onError)}>{t('submit')}</AppButton>
      </AppContainer>
    </form>
  );
};
